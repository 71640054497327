import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import "../styles/AlertDialog.css"

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#C74B30',
    },
  },
});

export default function AlertDialog(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [order_data, setOrder_data] = useState(props.order_data)
  const [data, setData] = useState(props.data)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleConfirm = () => {
    setOpen(false);
    navigate("/history", { state: data })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button className={`${classes.button} commn-dialog-btn`} variant="contained" color="primary" onClick={handleClickOpen}>
        Confirm Order
      </Button>
      <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <Box className="comm-alert-dialog">
            <DialogTitle id="alert-dialog-title">
                <Typography align='center' color='#EA6519' fontSize={"24px"}>Order Summary</Typography>
            </DialogTitle>
            <DialogContent>
              <div className='comm-dialog-body'>
                  <div className="commn-summary-input">
                      <label className="commn-summary-label">Chart Name</label>
                      <input value={order_data.chart_name} readOnly/>
                  </div>
                  <div className="commn-summary-input">
                      <label className="commn-summary-label">Chart Description</label>
                      <textarea value={order_data.chart_dsrp} readOnly/>
                  </div>
                  <div className="commn-summary-input">
                      <label htmlFor="type" className="commn-summary-label">Implant Type</label>
                      <input name="type" value={order_data.type} readOnly />
                  </div>
                  <div className="commn-summary-input">
                      <label htmlFor="deadline" className="commn-summary-label">Deadline</label>
                      <input name="deadline" value={order_data.deadline} readOnly/>
                  </div>  
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary" variant="contained" className={`${classes.button} comm-dialog-confitm-btn`} >
                Confirm
              </Button>
              <Button onClick={handleClose} color="secondary" variant="contained" className='comm-dialog-close-btn' autoFocus>
                Close
              </Button>
            </DialogActions>
            </Box>
      </Dialog>
    </div>
  );
}
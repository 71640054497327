import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';

ReactDOM.render(
  <BrowserRouter>
    <StylesProvider injectFirst>
      <App />
    </StylesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

import './styles/App.css';
import Home from './components/Home';
import Login from "./components/Login"
import Navbar from './components/Navbar';
import OrderHistory from './components/OrderHistory';
import PlaceOrder from './components/PlaceOrder';
import Footer from './components/Footer';
import Communication from './components/Communication';
import { Route, Routes, useLocation, Navigate } from "react-router-dom"

function App() {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('user');

  let nav_comp
  if (location.pathname !== "/login" && isLoggedIn){
    nav_comp = <Navbar />
  }
  console.log(isLoggedIn);
  return (
    <div className="App">
      {nav_comp}
      <Routes>
        <Route path="/login" element={isLoggedIn
              ? <Navigate to='/history' />
              : <Login />
        } />
        <Route path="/order" element={ isLoggedIn
              ? <PlaceOrder />
              : <Navigate to='/login' />
        } />
        <Route path="/history" element={ isLoggedIn
              ? <OrderHistory />
              : <Navigate to='/login' />
        } />
        <Route path="/communication" element={ isLoggedIn
              ? <Communication />
              : <Navigate to='/login' />
        } />
        <Route path="/" element={ isLoggedIn
              ? <Navigate to='/history' />
              : <Navigate to='/login' />
        }/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import { useEffect, useState } from "react"
import "../styles/Communication.css"
import { App as SendbirdApp } from 'sendbird-uikit'
import { useNavigate, useLocation } from "react-router-dom"
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import "sendbird-uikit/dist/index.css";
import AlertDialog from "./Dialog";


const useStyles = makeStyles({
    popper: {
      width: '100%',
      height: '100%',
    },
  });

function Communication(props){
    const navigate = useNavigate()
    const location = useLocation()

    const APP_ID = "2FDF19DB-41CA-40DB-98D1-BBFAC257B094"
    const USER_ID = "doctor1"

    const [order_data, setOrder_data] = useState(location.state.order_data)
    const [data, setData] = useState(location.state.data)

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePoperClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div className="commn-page">
            <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
                <Paper className='order-summary-popper-content'>
                    <Typography sx={{ p: 2 }}>
                        <div className="order-summary-popper-body">
                            <h2>Order Summary</h2>
                            <div>
                                <div className="commn-summary-input">
                                    <label className="commn-summary-label">Chart Name</label>
                                    <input value={order_data.chart_name} readOnly/>
                                </div>
                                <div className="commn-summary-input">
                                    <label className="commn-summary-label">Chart Description</label>
                                    <textarea value={order_data.chart_dsrp} readOnly/>
                                </div>
                                <div className="commn-summary-input">
                                    <label htmlFor="type" className="commn-summary-label">Implant Type</label>
                                    <input name="type" value={order_data.type} readOnly />
                                </div>
                                <div className="commn-summary-input">
                                    <label htmlFor="deadline" className="commn-summary-label">Deadline</label>
                                    <input name="deadline" value={order_data.deadline} readOnly/>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Paper>
            </Popper>
            <p className="commn-title">Communication</p>
            {/* parent communication  */}
            <div className="commn-parent">
                {/* dicom view div */}
                <div className="commn-dicom-view">
                        {/* <p>Dicom View</p> */}
                        <iframe src="http://210.103.91.184/login" className="commn-dicom-iframe"/>
                    </div>
                {/* messenger and controls div */}
                <div className="commn-msg-control">
                    {/* messenger div */}
                    <div className="commn-messenger">
                        {/* <p>Messenger</p> */}
                        <SendbirdApp appId={APP_ID} userId={USER_ID} className="commn-msg-sendbird"/>
                    </div>
                    {/* summary and controls div */}
                    <div className="commn-summary-control">
                        {/* order summary div */}
                        <div className="commn-summary">
                            <p>Order Summary</p>
                            <div className="commn-summary-input">
                                <label htmlFor="chart_name" className="commn-summary-label">Chart Name</label>
                                <input 
                                    name="chart_name" 
                                    value={order_data.chart_name} 
                                    readOnly
                                />
                            </div>
                            <div className="commn-summary-input">
                                <label htmlFor="chart_dsrp" className="commn-summary-label">Chart Description</label>
                                <textarea 
                                    name="chart_dsrp" 
                                    value={order_data.chart_dsrp} 
                                    readOnly
                                />
                            </div>
                            <div className="commn-summary-input">
                                <label htmlFor="type" className="commn-summary-label">Implant Type</label>
                                <input 
                                    name="type" 
                                    value={order_data.type} 
                                    readOnly
                                />
                            </div>
                            <div className="commn-summary-input">
                                <label htmlFor="deadline" className="commn-summary-label">Deadline</label>
                                <input 
                                    name="deadline" 
                                    value={order_data.deadline}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* controls div */}
                        <div className="commn-controls">
                            <span>Controls</span>
                            {/* <button className="commn-controls-btn">Confirm</button> */}
                            
                            <AlertDialog order_data={order_data} />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    )
}

export default Communication;
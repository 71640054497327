import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import cgbio_logo from "../images/icons/cgbio_nav_logo.png"
import user_avatar from "../images/icons/user_avatar.png"
import profile_icon from "../images/icons/account.png"
import logout_icon from "../images/icons/logout.png"
import "../styles/Navbar.css"

// drop down component
const DropdownMenu = ({ onProfileClick, onLogoutClick }) => (
    <ul>
      <li><button className="nav-avata-pop-btns" onClick={onProfileClick}>
            <img src={profile_icon} width="15px" style={{marginRight: "5px"}}/> Profile
        </button></li>
      <li><button className="nav-avata-pop-btns" onClick={onLogoutClick}>
            <img src={logout_icon} width="15px" style={{marginRight: "5px"}}/>   Logout
        </button></li>
    </ul>
);

function Navbar(){
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/history")
    }


    const [showDropdown, setShowDropdown] = useState(false);

    const handleAvatarClick = () => setShowDropdown(!showDropdown);
    const handleProfileClick = () => {
        // Handle profile click here
        setShowDropdown(false);
    };
    const handleLogoutClick = () => {
        // Handle logout click here
        setShowDropdown(false);
        localStorage.removeItem('user')
        navigate("/login")
    };

    return(
        <nav className="nav">
            <div className="nav-contents">
                <div className="nav-logo-container">
                    <img 
                        src={cgbio_logo}
                        alt="CGBio logo"
                        className="nav-logo"
                        onClick={handleClick}    
                    />
                </div>
                <span>PSI Order System</span>
                <div className="nav-avatar-container">
                    <img 
                        src={user_avatar}
                        alt="User Avatar"
                        className="nav-user-avatar"
                        onClick={handleAvatarClick} 
                    />
                    {showDropdown && (
                        <div className="nav-avatar-pop" style={{ position: 'absolute', top: '100%'}}>
                            <DropdownMenu
                                onProfileClick={handleProfileClick}
                                onLogoutClick={handleLogoutClick}
                            />
                        </div>
                    )}
                </div>
            </div>
            <hr className="nav-divider"/>
        </nav>

    )
}

export default Navbar;
import React, { useEffect } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import initCornerstone from './InitConerstone';

export default function DicomViewer({ file }) {
  console.log(file)
  const element = React.createRef();

  useEffect(() => {
    initCornerstone();
    const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
    cornerstone.loadImage(imageId).then(image => {
      cornerstone.enable(element.current);
      cornerstone.displayImage(element.current, image);
    });
  }, [file]);

  return <div style={{width:"100%", height:"100%"}} ref={element} />;
}


import React from "react"
import { useNavigate } from "react-router-dom"
import "../styles/Footer.css"

function Footer(){
    // const navigate = useNavigate()

    // const isLoggedIn = localStorage.getItem('user');

    // const handleLogout = (event) => {
    //     localStorage.removeItem('user')
    //     navigate("/login")
    // }


    // let logout_btn
    // if(isLoggedIn){
    //     logout_btn = <button className="footer-logout" onClick={handleLogout}>Logout</button>
    // }

 
    return (
        <footer className="footer">
            <p>Copyright © 2023 CGBio, All rights reserved</p>
        </footer>
    );
}

export default Footer
import { useEffect, useState } from "react"
import Select from "react-select"
import { useForm, useController } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import { date, string, z } from "zod"
import { useNavigate, useLocation } from "react-router-dom"
import DicomViewer from "./DicomViewer"
import "../styles/PlaceOrder.css"
import "react-datepicker/dist/react-datepicker.css"
import image_placeholer from "../images/icons/image placeholder.png"


const implant_options = [
    { value: "Implant", label: "Implant" },
    { value: "Guide", label: "Guide" },
    { value: "Simulator", label: "Simulator" }
]

const country_options = [
    { value: "kr", label: "KR" },
    { value: "usa", label: "USA" },
]

// const dateSchema = z.preprocess((arg) => {
//     if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
//   }, z.date());

const schema = z.object({
    chart_name: string().min(1, { message: "Chart Name is required" }),
    country_code: string(),
    chart_dsrp: string().optional(),
    type: string(),
    deadline: string().min(1, { message: "Deadline is required" }),
    address: string().min(1, { message: "Address is required" }),
    // file: string().min(1, {message: "Dicom File is required"})
})

const selectStyle = {
    // implement custome style to selector component
}

function PlaceOrder(props) {
    // FIXME: This is for demo, mocking communication feature
    const redirectUrl = "http://210.103.91.184/app"

    const navigate = useNavigate()
    const location = useLocation()
    const _ = require("lodash")

    // get datas from state
    const [data, setData] = useState(location.state.data)
    const [user, setUser] = useState(location.state.user)
    const read_only = _.isEmpty(user) ? false : true
    const submit_btn_text = _.isEmpty(user) ? "Submit" : "Update"

    // calculate today date and add 30 days to that
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() + 30);
    const minDateString = minDate.toISOString().split('T')[0];

    const { register, control, handleSubmit, formState } = useForm({
        defaultValues: user,
        resolver: zodResolver(schema)
    })
    const { errors } = formState
    const { field: country_field } = useController({
        name: "country_code",
        control,
        defaultValue: "kr"
    })
    const { field: implant_field } = useController({
        name: "type",
        control,
        defaultValue: "Guide"
    })

    const [cntryDefaultVal, setCntryDefaultVal] = useState()

    const handleSave = (formValues) => {
        if (!read_only) {
            // add some attributes to data
            formValues.id = data.length + 1
            formValues.status = "Awaiting Confirmation"
            // formValues['type'] =  formValues['implant_type']
            // delete formValues.implant_type
            // update data array
            data.push(formValues)
            console.log(data)
        }
        // navigate("/history", { state: data })
        // const data = formValues;
        navigate("/communication", { state: {order_data: formValues, data: data} })
    }

    const handleCancel = (event) => {
        navigate("/history", { state: data })
    }

    const countryHandleSelectChange = (option) => {
        country_field.onChange(option.value)
    }

    const implantHandleSelectChange = (option) => {
        implant_field.onChange(option.value)
    }

    useEffect(() => {
        // set the default values
        setCntryDefaultVal(props.ctryCodeDef)
    })

    // handle dicom file upload
    const [dicom_file, setDicom_file] = useState(null);

    const handleFileChange = (event) => {
        setDicom_file(event.target.files[0]);
        console.log(dicom_file)
    };



    return (
        <div className="order-container">
            <p className="order-form-title">Order Form</p>
            <div className="order-form-preview">
                <form className="order-form" onSubmit={handleSubmit(handleSave)}>
                    {/* 1st row of order form => chart_name, country_code */}
                    <div className="order-form-row">
                        <div className="order-input">
                            <label htmlFor="chart_name">Chart Name*</label>
                            <input {...register("chart_name")} readOnly={read_only} required />
                            <div style={{ color: "red" }}>{errors.chart_name?.message}</div>
                        </div>
                        <div className="order-input order-input-left">
                            <label htmlFor="country_code">Country Code*</label>
                            <Select
                                value={country_options.find(({ value }) => value === country_field.value)}
                                {...register("country_code")}
                                onChange={countryHandleSelectChange}
                                options={country_options}
                                className="order-select"
                                isDisabled={read_only}
                                required
                            />
                            <div style={{ color: "red" }}>{errors.country_code?.message}</div>
                        </div>
                    </div>

                    {/* 2nd row of order form => chart description */}
                    <div className="order-input">
                        <label htmlFor="chart_dsrp">Chart Description</label>
                        <textarea {...register("chart_dsrp")} readOnly={read_only} />
                        <div style={{ color: "red" }}>{errors.chart_dsrp?.message}</div>
                    </div>

                    {/* 3rd row of order form => implant type, Deadline */}
                    <div className="order-form-row">
                        <div className="order-input">
                            <label htmlFor="type">Implant Type*</label>
                            <Select
                                {...register("type")}
                                value={implant_options.find(({ value }) => value === implant_field.value)}
                                onChange={implantHandleSelectChange}
                                options={implant_options}
                                className="order-select"
                                isDisabled={read_only}
                                required
                            />
                            <div style={{ color: "red" }}>{errors.implant_type?.message}</div>
                        </div>
                        <div className="order-input  order-input-left">
                            <label htmlFor="deadline">Deadline*</label>
                            <input
                                type="date"
                                min={minDateString}
                                {...register("deadline")}
                                // readOnly={read_only} 
                                required
                            />
                            <div style={{ color: "red" }}>{errors.deadline?.message}</div>
                        </div>
                    </div>

                    {/* 4th row of order form => Delivery address, dicom file */}
                    <div className="order-form-row">
                        <div className="order-input">
                            <label htmlFor="address">Delivery Address*</label>
                            <input
                                {...register("address")}
                                // readOnly={read_only} 
                                required
                            />
                            <div style={{ color: "red" }}>{errors.address?.message}</div>
                        </div>
                        <div className="order-input  order-input-left">
                            <label htmlFor="file">Dicom File*</label>
                            <input
                                type="file" 
                                {...register("file")}
                                // readOnly={read_only} 
                                required
                                // accept='.dcm'
                                multiple
                                onChange={handleFileChange}
                            />
                            <div style={{ color: "red" }}>{errors.file?.message}</div>
                        </div>
                    </div>
                    <div className="order-form-buttons">
                        <button type="submit" className="order-form-btn"> {submit_btn_text} </button>
                        <button onClick={handleCancel} className="order-form-btn"> Cancel </button>
                    </div>
                </form>
                {/* FIXME: Show preview when dicom file successfully uploaded */}
                <div className="order-dicom-container">
                    <div className="order-dicom">
                        {dicom_file ? (
                            <DicomViewer file={dicom_file} /> )
                         : (
                            <a href={redirectUrl}>
                                <img src={image_placeholer} />
                            </a>
                         )}
                        
                    </div>
                    <p>Dicom Preview</p>
                </div>
            </div>
        </div>
    )
}

export default PlaceOrder;
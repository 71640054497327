import { useState } from "react";
import cgbio_logo from "../images/logo_type2_orange.png"
import { useNavigate, useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { useForm, useController } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import { date, string, z } from "zod"
import "../styles/Login.css"

const schema = z.object({
    username: string().min(1, {message: "Username is required"}),
    password: string().min(1, {message: "Password is required"}),
})

function Login() {
    const navigate = useNavigate()
    const [ loginError, setLoginError ] = useState({})
    const { register, control, handleSubmit, formState } = useForm({resolver:zodResolver(schema)})
    const { errors } = formState

    const [users, setUsers] = useState([
        {id: "1", username: "tester", pwd: "12345678"}, 
        {id: "2", username: "designer", pwd: "12345678"}
    ]);

    const handleSave = (formValues) => {
        const user_chk = users[users.findIndex((usr) => usr.username == formValues.username)]
        if(user_chk && formValues.password === user_chk.pwd) {
            setLoginError({})
            localStorage.setItem('user', JSON.stringify(user_chk))
            navigate("/history")
        } else {
            // loginError.msg = "Incorrect Credentials"
            setLoginError({"msg": "Incorrect Credentials"})
            console.log({"msg": "Incorrect Credentials"})
        }
        
    }

    return (
        <div className="login">
            <img src={cgbio_logo} alt="CGBio Logo" className="login-logo"/>
            
            <form onSubmit={handleSubmit(handleSave)} className="login-form">
                <p className="login-title">PSI Order System</p>

                <div className="login-denied">{ loginError?.msg }</div>
                
                <div className="login-input">
                    <label htmlFor="username" width="75px">Username</label>
                    <input
                        type="text"
                        {...register("username")}
                    />
                    <div style={{color: "red"}} className="login-error">{errors.username?.message}</div>
                </div>
                <div className="login-input">
                    <label htmlFor="password" width="75px">Password </label>
                    <input
                        type="password"
                        {...register("password")}
                    />
                    <div style={{color: "red"}} className="login-error">{errors.password?.message}</div>
                </div>
                <button type="submit" className="login-btn"> Login </button>
                <p className="login-pw-rest">Forgot Password</p>
            </form>
            
        </div>
    )
}

export default Login;
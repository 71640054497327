import { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component"
import { useNavigate, useLocation } from "react-router-dom"
import "../styles/OrderHistory.css"
import dots_icon from "../images/icons/dots.png"
import edit_icon from "../images/icons/edit.png"
import delete_icon from "../images/icons/trash.png"

// style for table
const customStyles = {
rows: {
    style: {
        '&:hover': {
            backgroundColor: '#F5F5F5'
        },
    }
},
};

function OrderHistory(){
    const navigate = useNavigate()
    const location = useLocation()

    const [data, setData] = useState([
        { id: 1, chart_name: 'Left Leg Ankle Design', status: "Printing", type: "Implant", deadline: "2023-03-12"},
        { id: 2, chart_name: 'Liver Design', status: "Awaiting Confirmation", type: "Guide", deadline: "2023-05-07"},
        { id: 3, chart_name: 'Right Wrest Design', status: "Delivering", type: "Implant", deadline: "2023-02-28"},
        { id: 4, chart_name: 'Right Hip Design', status: "Delivered", type: "Simulator", deadline: "2023-02-25"},
        { id: 5, chart_name: 'Toe of right leg Design', status: "Printing", type: "Guide", deadline: "2023-08-17"},
    ]);

    const columns = [
        // { name: "Id", selector: row => row.id, id: "id", sortable: true},
        { name: "Chart name", selector: row => row.chart_name, id: "chart_name", sortable: true},
        { name: "Status", selector: row => row.status, id: "status", sortable: true},
        { name: "Type", selector: row => row.type, id: "type", sortable: true},
        { name: "Deadline", selector: row => row.deadline, id: "deadline", sortable: true},
        { name: "Actions", 
            selector: row => <div>
                    <img 
                        src={edit_icon} 
                        className="order-actions" 
                        onClick={() => handleRowClick(row)}
                    />
                    <img 
                        src={delete_icon} 
                        className="order-actions" 
                        // implement delete option
                        // onClick={() => }
                    />
                    {console.log(row)}
                </div>, 
            width: "150px"
        }
    ]

    const [loading, setLoading] = useState(false)
    const [perPage, setPerPage] = useState(10)

    useEffect(() => {
        updateData()
    }, [])

    const updateData = () => {
        const _ = require("lodash")
        const new_data = location.state

        if(!(_.isEmpty(new_data))){
            setLoading(true)
            // new_order.id = data.length+1
            // new_order.status = "Awaiting Confirmation"
            // new_order['type'] =  new_order['implant_type']
            // delete new_order.implant_type
            // setData([
            //     ...data,
            //     new_order
            // ])
            setData(new_data)
            setLoading(false)
        }
        // console.log(data)
    }

    const handleSave = (values) => {
        console.log({values})
    }

    const handleClick = () => {
        navigate("/order", {state: {data: data, user: {}}})
    }

    const handleRowClick = (row_data) => {
        navigate("/order", {state: {data: data, user: row_data}})
    }

    return (
        <div className="table-container">
            <p className="table-title">Order History</p>

            <DataTable
                // title="Order History"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                onRowClicked={(row) => handleRowClick(row)}
                className="table"
                defaultSortFieldId="deadline"
                // defaultSortAsc={false}
                // conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
            />
            <button onClick={handleClick}  className="table-btn">Place Order</button>
        </div>
    )
}

export default OrderHistory;